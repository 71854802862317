import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";

import Form from "./Form/Form";
import * as wizardActions from './store/actions/wizard';
import * as actionTypes from '../../store/actions/actionTypes';

const Document_v2 = ({documentPage}) => {
    const dispatch = useDispatch();
    const formTopRef = useRef(null);
    const currentStep = useSelector(state => state.wizard.currentStep);
    const scrollToTop = () => formTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const currentDocument = useSelector(state => state.main.currentDocument);

    const dispatchWizardSave = () => dispatch(wizardActions.wizardSave());

    useEffect(() => {
        // dispatch({type: 'DEV_document_v2_useEffect'});
        console.log('[Document_v2] useEffect([])');
        dispatch(wizardActions.wizardInitEdit(documentPage, currentDocument.documentData))

        return () => {
            console.log('[Document_v2] useEffect([]) Destroy save');
            dispatch(wizardActions.wizardSave(false));
            dispatch({type: actionTypes.REVIEW_RESET});
        }
    }, []);

    useEffect(() => {
        console.log('[Document_v2] useEffect([documentPage])');
        if(currentStep !== parseInt(documentPage)) {
            scrollToTop();
            dispatch(wizardActions.wizardChangeStep(documentPage));
            dispatch(wizardActions.wizardSave(false));
        }
    }, [documentPage]);

    return <>
            <div ref={formTopRef}/>
            <Form onSubmit={dispatchWizardSave} />;
        </>
}

export default Document_v2;